@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 600px) {
  .floating-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000; /* Adjust the value as needed */
  }
}


#selectOption{
    display: inline-table;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 15px;
}
#switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 24px;
}
  
#switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a5a5a5;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: rgb(255, 255, 255);
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #00d47a;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #00d47a;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(30px);
}
  
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}
.modal {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    transition: all .25s linear; 
  }
  
  .modal.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
.modal-icon {
    width: 35px;
    height: 35px;
    margin-bottom: 0.8rem;
  }
  .modal-dialog {
    position: relative;
    width: 330px;
    margin: auto;
    pointer-events: none;
  }
  /* @media (min-width: 576px) {
    .modal-dialog-box {
        max-width: 300px;
        margin: 1.75rem auto;
    }
    } */
body {
  margin: 0;
  padding: 0;
  background-color: rgb(247 247 247 / 80%);
  overflow-y: auto;
  font-family: "Lato", sans-serif;
}

label {
  font-weight: 500;
  color: #555;
  margin-bottom: 5px;
  /* font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif; */
}

b,
strong {
  color: #555;
}

a {
  color: #ff6500;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #ff8c00;
  text-decoration: none;
}
/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}
.bg-purple {
  background-color: #581c8c;
}
.h-10 {
  height: 75px !important;
}
.pulse {
  background: #20ca02;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -16px 0 0 -16px;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  box-shadow: 0 0 1px 2px #20ca02;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

header {
  position: fixed;
  width: 100%;
  background: #581c8c;
  /* padding: 10px 15px; */
  border-bottom: none;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 18%);
  z-index: 999;
}

.header-title {
  font-weight: bold;
  color: #ffffff;
  /* text-transform: capitalize; */
  font-size: 18px;
  margin: 3px 0;
}

.header-logo {
  width: auto;
  height: 34px;
}

.search-bar {
  padding: 5px 0 10px;
  margin-bottom: 10px;
  /* border-bottom: 0.5px solid #dedede; */
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: auto;
  height: 17px;
  line-height: normal;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  top: 11px;
  left: 11px;
}

.select-ctg {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
}

.bottom-btns {
  position: absolute;
  bottom: 2.5rem;
  left: 10px;
}

.bottom-btn-link {
  padding: 10px 13px;
  background-color: #f87b14;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  line-height: 1.2;
  transition: ease-in 200ms;
}

.bottom-btn-link:focus,
.bottom-btn-link:hover {
  color: #fff;
  background-color: #f87b14;
  text-decoration: none;
}

.bottom-btn-icon {
  font-size: 25px;
  font-weight: bold;
  line-height: normal;
  margin-top: -5px;
  margin-right: 6px;
}

.popover-box {
  display: block;
  position: absolute;
  padding: 1rem 1.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
  bottom: 18px;
  left: -113px;
  z-index: 9999999;
  min-width: 240px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
}

.popover-box::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 45%;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.shop-name {
  font-size: 0.9rem;
  display: inline-block;
  font-weight: bold;
  color: #222;
  margin-bottom: 5px;
  line-height: normal;
}

.shop-adrs {
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 10px;
}

.button-link {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  text-align: left;
  background-color: #581c8c;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.button-link:hover {
  background-color: #4b0381;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Home Page */

.section-banner {
  margin: 3rem 0;
}

.user-image {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 5px solid rgb(245 243 243 / 72%);
  background: transparent;
  /* margin-right: 1rem; */
  margin-top: -3rem;
}

.user-info {
  width: 70%;
  margin-left: 20px;
  margin-top: 0;
  color: #fff;
}

.user-info .user-name {
  font-size: 17px;
  font-weight: 700;
  /* color: #fff; */
  color: #212121;
}

.user-points span {
  font-weight: 900;
  font-size: 15px;
  width: 150px;
  border-radius: 4px;
  margin-top: 10px;
}

.user-info .user-cupons-holder {
  display: flex;
  flex-direction: row;
}

.user-info .user-address,
.user-info .user-phone,
.user-info .user-points,
.user-info .user-promotions-holder .user-promotions {
  font-size: 15px;
  /* color: #fff; */
  color: #222;
}

.section-banner .card {
  background: #fff;
  border-radius: 10px;
  border: none;
  margin-top: 2rem;
  padding: 1rem;
}

/* Checkout CSS */

.shipping-address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.shipping-address .settings {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0px 10px;
  font-weight: normal;
  text-transform: capitalize;
}

.shipping-info {
  position: relative;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

.shipping-info .info-list-with_icon {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 1.3rem;
  margin-bottom: 1rem;
}

.shipping-info .info-list-with_icon:last-child {
  margin: 0;
}

.shipping-info .info-icon {
  color: #ff6500;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 2px;
}

.shipping-info .edit-address {
  position: absolute;
  top: 8px;
  right: 8px;
}

/* Payment CSS */
.payment {
  margin-bottom: 1.5rem;
}
.payment img,
.payment img,
.payment img,
.payment img {
  height: 45px;
  width: auto;
}
.payment-select {
  border: transparent;
  border-radius: 7px;
  padding: 5px 0;
  cursor: pointer;
}
.payment-select.active {
  border: 2px solid #ff6500;
}

.map-img {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}
.order-summary {
  border: 1px solid #f7f7f7;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
  border-radius: 10px;
  padding: 0 10px;
}
.order-summary .item-amount {
  color: #676767;
  font-size: 16px;
  font-weight: 900;
  margin-right: 5px;
}
.order-summary .item-name {
  color: #676767;
  font-size: 16px;
  font-weight: 700;
}

.order-summary .item-price {
  font-size: 18px;
  color: #676767;
  font-weight: 900;
  text-align: right;
}

.order-summary .total-price {
  color: #ff6500;
  font-size: 22px;
  font-weight: 900;
}

.order-summary__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #fafafa;
}
.order-item__holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 250px;
  margin-right: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Profile CSS */

/* .profile-top-section {
  background: rgb(99 81 61);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px 0;
}
.profile-top-section .point {
  color: #fff;
  font-size: 17px;
  text-align: center;
  font-weight: bold;
}
.profile-top-section .exp-date {
  margin: 10px 0 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
} */

.profile-img_holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 0.5rem; */
  padding: 10px;
  background: #ffffff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  margin-top: 4rem;
}

.profile-info_holder .user-name {
  font-size: 21px;
  color: #4c4685;
  font-weight: 600;
}

.profile-info_holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background: #fff;
  /* border-top: 2px solid #f1f1f1; */
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 8px 15px;
  margin-bottom: 1.5rem;
}

.profile-info_holder .text {
  font-size: 14px;
  color: #8a8484;
  /* margin-bottom: 7px; */
}

.profile-info_holder .icon {
  font-size: 14px;
  color: #007bff;
  margin-right: 7px;
}

.profile-link-list {
  display: flex;
  flex-direction: column;
}

.profile-link-list__holder {
  background-color: #ffffff;
  box-shadow: 2px 3px 4px rgb(0 0 0 / 3%);
  border-radius: 10px;
}

.profile-link-list .title {
  color: #969696;
  font-size: 12px;
  text-transform: uppercase;
}

.profile-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 12px;
  font-size: 14px;
  border-bottom: 2px solid #f9f9f9;
  /* background: #ffffff;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #444;
  box-shadow: 2px 3px 4px rgb(0 0 0 / 3%); */
}

.profile-link:focus,
.profile-link:hover {
  text-decoration: none;
  color: #ff8c00;
}

.profile-link span {
  color: #676767;
  font-weight: 500;
  font-size: 15px;
}

.profile-link .icon {
  margin-right: 1rem;
  background-color: #ff8c00;
  color: #ffffff;
  width: 20px;
  height: 20px;
  font-size: 16px;
  padding: 5px;
  border-radius: 3px;
}

.profile-img img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 15px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #57a857;
  color: #fff;
  border-radius: 5px;
}

.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}

/* Horizontal Scroll Categories */

.ctg--content {
  /* background-color: rgb(99 81 61 / 5%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); */
  margin: 10px 20px 0 10px;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ctg--content .ctg-name-holder {
  text-align: center;
  padding: 8px 8px 10px;
  min-width: 75px;
}

.ctg--content .ctg-name {
  margin: 0;
  font-size: 13px;
  line-height: 1.2;
  color: #585858;
  /* height: 14px; */
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 95%;
  text-align: center;
}

.ctg--content .ctg-img-holder {
  text-align: center;
  padding: 8px;
  background: #ffffff;
  border-radius: 40px;
  box-shadow: inset 3px 3px 4px rgb(0 0 0 / 6%);
}

.ctg--content .ctg-img {
  width: 66px;
  height: 66px;
  border-radius: 100%;
  border: 1px solid #f1f1f1;
}

.view-all-ctg {
  padding: 3px 6px;
  font-size: 14px;
  color: #24448f;
}

.box-scroll {
  /* min-height: 110px; */
  min-height: 96px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-bottom: 1rem;
  margin-top: 0;
}

.box-scroll::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

.box-scroll::-webkit-scrollbar-thumb {
  background: rgb(99 81 61 / 5%);
  border-radius: 10px;
}

.box-scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(99 81 61 / 15%);
}

.box-scroll .ctg--content:first-child {
  margin-left: 0;
}

/* Horizontal Scroll Banner View */

.box-scroll .banner-img:first-child {
  margin-left: 0;
}

/* .banner-img-holder {
  background-color: #f5f7ff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
} */

.banner-link .banner-img {
  width: 500px;
  height: auto;
  border-radius: 10px;
  margin: 5px 8px;
}

/* .banner-link:hover .banner-img {
  opacity: 0.7;
} */

/* .banner-img-holder:hover img {
  opacity: 0.7;
} */

/* All Catgegories View */

.all-ctg-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.all-ctg-grid .ctg-list {
  flex: 0 1 20%;
}

.all-ctg-grid .ctg-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  margin: 6px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 5px;
  box-shadow: 2px 3px 4px rgb(0 0 0 / 3%);
}

.all-ctg-grid .ctg-item-img-holder {
  text-align: center;
  padding: 15px 10px 5px;
  border-radius: 12px;
}

.all-ctg-grid .ctg-item-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 8px;
  background: #ffffff;
  box-shadow: inset 3px 3px 4px rgb(0 0 0 / 6%);
}

.all-ctg-grid .ctg-item-name-holder {
  padding: 4px 12px 12px;
}

.all-ctg-grid .ctg-item-name {
  margin: 0;
  /* padding: 4px 12px 12px; */
  font-size: 14px;
  line-height: 1.2;
  color: #585858;
  text-align: center;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.category-title {
  font-weight: bold;
  color: #676767;
  font-size: 17px;
  margin: 0 0 7px;
  text-transform: capitalize;
  text-align: left;
}

/* Banners */

.banner-carousel {
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

.banner-items {
  border-radius: 0.4rem;
  max-height: 400px;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: cuponer;
  background-color: transparent;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

/* Product Detail */

.item-detail-info .item-name {
  font-size: 18px;
  font-weight: normal;
  color: #565656;
  margin: 0;
  padding-bottom: 0rem;
  line-height: normal;
}

.item-detail-info .item-price {
  font-size: 27px;
  font-weight: 900;
  color: #444;
  margin: 8px 0;
}

.item-detail-info .item-price-cross {
  font-size: 15px;
  text-decoration: line-through;
  font-style: italic;
  color: #555;
}

.product-detail-attr-wrap {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.product-detail-attr-wrap .pda-box {
  padding: 7px 13px;
  font-size: 11px;
  width: auto;
  height: auto;
  border-radius: 3px;
  border: 2px solid #d8d8d8;
  margin: 7px 13px 10px 0px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.product-detail-attr-wrap .pda-box.active {
  border: 3px solid #e99b27;
}

.product-detail-attr-wrap .pda-box img {
  max-width: 99%;
  max-height: 99%;
  object-fit: cover;
  border-radius: 4px;
}

.product-detail-attr-wrap .pda-box span {
  font-weight: bold;
  font-size: 14px;
}

.qty-input {
  width: 110px;
  height: 30px;
  border: none !important;
  z-index: -1;
  background: #f9f9f9;
  border-radius: 2rem !important;
}

.qty-input:focus,
.qty-input:hover {
  outline: none !important;
}

.qty-input-holder b {
  border-radius: 2rem !important;
  background: #fff !important;
  cursor: pointer !important;
}

.product-detail__attr {
  border: 2px solid #f7f7f7;
  border-radius: 5px;
  margin-bottom: 10px;
}

.product-detail__attr .title {
  font-size: 18px;
  padding: 7px 12px;
  border-bottom: 2px solid #f7f7f7;
}

/* Product Grid Layout */

.block-label {
  font-weight: bold;
  color: #676767;
  /* text-transform: capitalize; */
  font-size: 23px;
  margin-bottom: 15px;
}

.mobile-product-grid {
  display: flex;
  flex: 0 1 25%;
}

.banner-grid {
  display: flex;
  flex: 0 1 100%;
}

.mobile-product-grid:hover {
  text-decoration: none;
}

.margin-fix {
  margin-left: -21px;
  margin-right: -21px;
}

.products,
.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.product-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 6px;
  padding: 0;
  background: #ffffff;
  box-shadow: 2px 3px 4px rgb(0 0 0 / 3%);
}

.banner-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 6px;
}

.banner-list-item {
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 6px;
}

.banner-list-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.banner-img-holder img {
  width: 100%;
  border-radius: 10px;
}

.banner-list img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-row-title {
  font-weight: bold;
  color: #676767;
  /* text-transform: capitalize; */
  font-size: 17px;
  margin: 0;
  padding: 5px 0;
  height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.view-all {
  text-align: right;
  padding: 2px 5px;
}

.view-all a {
  color: #24448f;
  text-transform: capitalize;
  font-size: 14px;
  overflow: hidden;
}

.product-info {
  padding: 7px 12px;
  text-align: left;
}

.product-info .item-name {
  margin: 0 0 6px;
  font-size: 14px;
  height: 17px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 99%;
  color: #8c8c8c;
}

.product-info .item-price {
  font-weight: 900;
  font-size: 18px;
  margin: 0;
  line-height: normal;
  color: #555;
}

.product-card:hover {
  text-decoration: none;
  color: #000;
}

.product-card img {
  width: 100%;
  padding: 10px 10px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.product-card:hover img {
  opacity: 0.7;
}

.product-card .product-info {
  margin-top: 0;
}

/* Product List CSS */

.product__image-container {
  margin: 0;
}

.product-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 0;
  margin-bottom: 1rem;
  border: 1px solid #f7f7f7;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

.product-list-item img {
  height: auto;
  width: 105px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: inline-block;
  cursor: zoom-in;
  padding: 5px;
  border-radius: 10px;
}

.order-item {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.order-item .item-name,
.order-item_name {
  margin: 0 0 5px;
  font-weight: normal;
  font-size: 13px;
  color: #676767;
  text-align: left;
}

.order-item .item-name a {
  color: #5a5a5a;
  max-width: 85%;
  display: block;
}

.order-item .item-attr {
  font-size: 11px;
  margin-bottom: 2px;
  color: #555;
}

.order-item .item-price {
  font-size: 19px;
  font-weight: bold;
  color: #555;
  margin: 0;
}

.order-item .item-point {
  position: absolute;
  bottom: 3rem;
  right: 10px;
  display: block;
  margin-top: 6px;
  font-size: 19px;
  color: #24448f;
  font-weight: bold;
}

.cart-item-total-price {
  font-size: 19px;
  color: #4c4685;
  font-weight: bold;
}

.product-list-info {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 8px 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.product-list-info .item-name {
  font-size: 15px;
  line-height: normal;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 90%;
}

.product-list-info .item-name a {
  color: #676767;
}

.product-list-info .item-attr {
  font-size: 13px;
}

.product-list-info .item-price {
  font-size: 18px;
  color: #676767;
  font-weight: 700;
  margin-top: 3px;
}

.product-list-info .qty-input-holder {
  padding-top: 7px;
}

.product-list-info .qty-input-holder span {
  font-size: 15px;
  color: #676767;
  margin-right: 7px;
}

.no-data-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255 255 255);
  padding: 1.5rem;
  text-align: center;
  border-radius: 10px;
}

.no-data-holder img {
  width: 50px;
  margin: 10px 0;
}

.no-data-msg {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #828282;
  font-size: 15px;
  font-weight: normal;
}

.link-icon {
  font-size: 20px;
}

/* Product Detail CSS */

.product-detail-grid {
  display: flex;
  line-height: 1.3;
  flex-direction: column;
  padding-bottom: 5rem;
}

.product-detail-grid img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  display: inline-block;
  cursor: zoom-in;
}

.product-detail-info .name {
  margin: 0;
  font-size: 14px;
  color: #212121;
}

.product-detail-info .price {
  font-weight: 900;
  font-size: 24px;
  margin: 0;
  line-height: normal;
  color: #24448f;
}

.product-detail-info .stock {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
}

.product-detail-info .color-box {
  border-radius: 100%;
  background: #24448f;
  width: 35px;
  height: 35px;
  border: 2px solid #d5cbcb;
  cursor: cuponer;
}

.product-detail-info .color-box1 {
  border-radius: 100%;
  background: #ff4c4b;
  width: 35px;
  height: 35px;
  border: 2px solid #d5cbcb;
}

.product-detail-info .label {
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}

.product-detail-info .qty-input {
  width: 130px;
  height: 30px;
  border: 1px solid #e8e8e8 !important;
  border-radius: 3px;
}

.bottom-fixed-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 0;
  padding: 10px;
  box-shadow: 2px -3px 4px rgb(0 0 0 / 3%);
  /* border-top: 1px solid #dedede; */
  background: #ffffff;
  width: 100%;
  z-index: 999;
}

.bottom-fixed-area .btn {
  width: 155px;
  margin-left: 8px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fluid,
.coupon-detail-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  font-size: 14px;
}

.fluid__instructions,
.coupon-detail-info {
  flex: 0 0 auto;
  margin: 0;
}

.fluid__image-container,
.coupon-detail_img {
  flex: 0 0 30%;
  margin: 0;
  z-index: 1;
}

.product_detail_image {
  width: 100%;
  height: auto;
  padding: 0;
  border: none;
  border-radius: 12px;
  background: #f9f9f9;
}

.error {
  background-color: #f7f7f7;
}

.error .icons {
  font-size: 100px;
  color: #999;
  margin-bottom: 0.5rem;
}

.error-content {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-content h1 {
  font-size: 3rem;
  color: #999;
  font-weight: 500;
  margin-bottom: 0;
}

.error-content h4 {
  font-weight: 400;
  color: #999;
}

.error-content p {
  color: #999;
}

@media (min-width: 480px) {
  .fluid,
  .coupon-detail-grid {
    flex-direction: row;
  }
  .fluid__instructions,
  .coupon-detail-info {
    flex: 0 0 50%;
  }
  .fluid__image-container,
  .coupon-detail_img {
    flex: 0 0 50%;
    margin: 0;
    z-index: 1;
  }
}

.gallery-wrap .img-big-wrap {
  margin-bottom: 0;
  overflow: hidden;
  background-color: #fff;
}

.gallery-wrap .img-big-wrap a {
  text-align: center;
  display: block;
}

.item-gallery {
  margin-bottom: 0;
}

.gallery-wrap .img-big-wrap .item-gallery img {
  height: 70px;
  width: 70px;
  display: inline-block;
  cursor: zoom-in;
  margin-right: 8px;
  padding: 5px;
  margin-top: 0;
  border-radius: 5px;
  border: 2px solid #f7f7f7;
  /* background: #f9f9f9; */
}

.gallery-wrap .img-small-wrap {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  max-width: 430px;
  margin: 10px 0;
}

.img-small-wrap::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

.img-small-wrap::-webkit-scrollbar-thumb {
  background: #e2e9fa;
  border-radius: 10px;
}

.img-small-wrap::-webkit-scrollbar-thumb:hover {
  background: #e2e9fa;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 7px;
  color: #f00;
  background-color: #ffecec;
  font-size: 16px;
  padding: 2px 6px;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
}

.delete-button:hover {
  background: #e53a29;
  color: #fff;
}

/* Section */

.top-section {
  padding: 1rem 0 2rem;
  background: rgb(99 81 61 / 86%);
}

.top-section-title {
  text-align: center;
  color: #fff;
  padding: 5px 0;
  margin: 0;
  font-size: 19px;
  text-transform: capitalize;
  /* letter-spacing: 0.05em; */
  font-weight: bold;
}

.top-section-search .input-group {
  margin: 5px 0;
}

.top-section-search .input-group .form-control {
  background: rgba(0, 0, 0, 0.18);
  width: 100%;
  border: none;
  border-radius: 20px;
  color: #fff;
  padding: 8px 16px;
  height: 3rem;
  font-size: 15px;
}

.top-section-search .input-group .form-control::-webkit-input-placeholder {
  color: #f8f8f8;
}

.top-section-search .input-group .form-control:-ms-input-placeholder {
  color: #f8f8f8;
}

.top-section-search .input-group .form-control::placeholder {
  color: #f8f8f8;
}

.top-section-search .input-group-append {
  padding: 13px;
  position: absolute;
  right: 3px;
  color: #f8f8f8;
  cursor: pointer;
  z-index: 8;
}

.content-section {
  padding: 3rem 0;
  /* padding-top: 5rem; */
  /* background-color: #581c8c; */
  min-height: 100vh;
}

.row-margin-fix {
  margin-right: -6px;
  margin-left: -6px;
  flex: 1 1 100%;
}

.title-small {
  font-weight: bold;
  color: #676767;
  font-size: 17px;
  text-transform: capitalize;
  margin: 3px 0;
}

.title-small-uppercase {
  font-size: 16px;
  font-weight: bold;
  color: #444;
  text-transform: uppercase;
  margin: 3px 0;
}

.load-content-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1rem;
  margin-bottom: 1rem;
}

.load-content-text {
  /* padding: 1rem; */
  min-height: 4rem;
}

.load-content-img {
  height: 30px;
}

/* Login Page CSS */

/* #materialBg {
  background: rgb(1, 41, 83);
  background-image: linear-gradient(rgb(85, 160, 241), #333b63);
  margin: 0;
  padding: 2rem 0;
  min-height: 100vh;
} */

.pattern-bg {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 100vh;
}

.login-page-links a {
  color: #ff6500;
  font-weight: bold;
}

.login-card,
.register-info {
  margin: 0 1.5rem;
}
.account-reg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5rem;
}

.logo {
  width: 120px;
  height: auto;
}

.logo-text {
  color: #fff;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.2rem;
  text-transform: capitalize;
}

.login-title,
.register-title,
.change-password-title {
  color: #4c4c4c;
  padding: 10px 0;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}

.shipping-title {
  font-size: 17px;
  margin-top: 21px;
}

.login-card .card-body,
.register-form .card-body {
  box-shadow: 0px 2px 4px #dedede;
}

.login-card .form-control {
  font-size: 15px;
  color: #3c3d46;
  background: rgba(255, 255, 255, 1);
  height: 45px;
}

.register-form .form-control {
  font-size: 15px;
  color: #3c3d46;
  background: rgba(255, 255, 255, 1);
  height: 45px;
}

.react-tel-input .form-control {
  height: 45px;
}
.login-card .form-control:focus,
.login-card .form-control:hover,
.register-form .form-control:focus,
.register-form .form-control:hover {
  background: rgba(255, 255, 255, 1);
  color: #3c3d46;
  box-shadow: none;
  outline: none;
}

.btn-login {
  color: #fff;
  background: rgb(243, 79, 3);
  padding: 8px 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 45px;
}

.btn-login:hover {
  color: #fff;
  background: rgb(243, 79, 3);
}

.card {
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

.card-body,
.custom-card .card-body,
.login-card .card-body,
.register-form .card-body {
  padding: 1rem;
  /* border: 1px solid #f7f7f7; */
  background: #ffffff;
  border-radius: 10px;
}

.change-password-form .card-body {
  background-color: #fff;
  border-radius: 10px;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  padding-left: 48px;
  background: #fff !important;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}

.login-image,
.register-image,
.change-password-image {
  width: 90px;
  height: auto;
}

.header-image {
  width: 40px;
  height: auto;
}

.logo-slogan {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  color: #4c4685;
}

.login-card .input-style,
.register-form .input-style {
  width: 100%;
  border: none;
  border-radius: 0;
  outline: none;
  padding: 12px 30px;
  background: transparent;
  font-size: 14px;
  color: #3c3d46;
  box-sizing: border-box;
  transition: 0.3s;
  box-shadow: none;
  border-bottom: 0.5px solid #dadada;
}

.login-card .icon,
.register-form .icon {
  position: absolute;
  color: #585858;
  transition: 0.3s;
  font-size: 18px;
  top: 20px;
  right: 10px;
}

.login-card .help-block {
  color: rgb(241, 2, 2) !important;
  margin-top: 3px;
}

.form-group {
  position: relative;
}

.login-card ::-webkit-input-placeholder, .register-form ::-webkit-input-placeholder {
  color: #3c3d46;
  opacity: 1;
}

.login-card :-ms-input-placeholder, .register-form :-ms-input-placeholder {
  color: #3c3d46;
  opacity: 1;
}

.login-card ::placeholder,
.register-form ::placeholder {
  color: #3c3d46;
  opacity: 1;
}

.opt-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.btn-sms-otp {
  color: #fff;
  background: #ff7700;
  padding: 8px 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 45px;
}

.btn-sms-otp:hover {
  color: #fff;
  background: #fc8115;
}

.btn-email-otp {
  color: #fff;
  background: #ffbd00;
  padding: 8px 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 45px;
}

.address-title {
  text-align: center;
  color: #676767;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}

/* Language CSS */

.language-select {
  width: 100%;
  padding: 1rem;
}

.language-list {
  /* border: 1px solid #eee; */
  margin-bottom: 10px;
}

.language-list_item {
  border-bottom: none;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #444;
  box-shadow: 2px 3px 4px rgb(0 0 0 / 3%);
}

.language-list_item img {
  width: auto;
  height: 25px;
  margin-right: 1rem;
}

.language-list_flag {
  padding: 2px;
  border-radius: 5px;
}

.language-title {
  font-weight: bold;
  color: #676767;
  text-transform: capitalize;
  font-size: 17px;
  margin: 0 0 7px;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 1rem;
}

.language-list_label {
  display: block;
  margin: 0;
  font-size: 16px;
}

.text-info {
  color: #676767 !important;
  font-weight: bold;
}

.text-muted {
  color: #676767;
  font-weight: 400;
}

.language-check.checked {
  color: #676767;
  margin-left: 3px;
  margin-top: 8px;
  width: 14px;
  height: 8px;
  border-bottom: solid 3px currentColor;
  border-left: solid 3px currentColor;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/*======= Map Styling ============*/

.gmnoprint a,
.gmnoprint span,
.gmnoprint {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

.login-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-remember-term {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 300;
}

.filter-dropdown button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 3px 10px;
}

.filter-dropdown button img {
  width: 20px;
  height: auto;
}

.btn-secondary {
  padding: 10px 30px;
  font-weight: bold;
  height: 45px;
}
.filter-dropdown .btn-secondary:not(:disabled):not(.disabled).active:focus,
.filter-dropdown .btn-secondary:not(:disabled):not(.disabled):active:focus,
.filter-dropdown .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  background: none;
  border: none;
  background-color: transparent;
}

.filter-dropdown .btn-secondary:focus,
.filter-dropdown .btn-secondary:hover {
  box-shadow: none;
  background: none;
  border: none;
  background-color: transparent;
}

.filter-dropdown .dropdown-menu {
  position: absolute;
  left: -30px !important;
  padding: 3px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Bottom Nav */

.bottom-nav {
  position: fixed;
  z-index: 9;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px 0;
  box-shadow: 2px -3px 4px rgb(0 0 0 / 3%);
  /* border-top: 1px solid #dedede; */
}

.bottom-nav .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px 1rem;
  color: #999;
}

.bottom-nav .nav-link.active {
  color: #4c4685;
}

.bottom-nav .nav-label {
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
}

.bottom-nav .nav-icon {
  font-size: 1.4rem;
  margin-bottom: 0;
}

.page-title-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  font-size: 1.1rem;
  margin: 0;
}

.header-logo-text {
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 10px;
  letter-spacing: 0.08rem;
  font-weight: normal;
}

.header-logo-text:focus,
.header-logo-text:hover {
  color: #fff;
}

.cart-holder {
  position: relative;
  padding: 4px 9px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
}

.cart-holder:hover {
  color: #fff;
}

.cart-holder .cart-count {
  position: absolute;
  top: -5px;
  right: -2px;
  padding: 2px 6px;
  border-radius: 12px;
  background-color: #f62604;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.4;
  z-index: 2;
}

.cart-holder .icon {
  font-size: 1.2rem;
}

.cart-holder .text {
  font-size: 0.9rem;
  margin-left: 14px;
  font-weight: bold;
  color: #555;
}

/* Horizontail Show View CSS */

.shop--content {
  /* margin: 5px 15px 0 10px; */
  margin: 5px 0 0 10px;
  max-width: 90px;
}

.shop--content .shop-name-holder {
  text-align: center;
  padding: 7px 10px 10px;
}

.shop--content .shop--name {
  margin: 0;
  font-size: 13px;
  line-height: normal;
  color: rgb(99 81 61 / 90%);
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 70px;
}

.shop--content .shop-img-holder {
  text-align: center;
}

.shop--content .shop-img {
  width: 60px;
  height: 60px;
  /* border-radius: 10px; */
  border-radius: 50%;
  padding: 6px;
  background: #ffffff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 8%);
}

.view-all-shop {
  padding: 3px 6px;
  font-size: 14px;
  color: #ff6500;
}

.box-scroll .shop--content:first-child {
  margin-left: 0;
}

/* Shop Views CSS */

.shop-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px 12px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

.shop-list-item:hover {
  text-decoration: none;
}

.shop-list-item .shop-item-img {
  height: auto;
  width: 100px;
  border-radius: 6px;
  cursor: zoom-in;
}

.shop-item-name-holder {
  padding: 5px 10px;
}

.shop-list-item .shop-item-name {
  font-weight: normal;
  font-size: 16px;
  color: #676767;
}

.shop-location {
  margin-top: -5px;
}

.shop-location .name {
  color: #999;
  font-size: 12px;
  margin-left: 5px;
}

.shop-location .icon {
  color: #007bff;
  font-size: 13px;
}

.shop-location .icon:hover {
  color: #007bff;
}

.shop-list-item .view-map {
  position: absolute;
  bottom: 0.5rem;
  right: 12px;
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: #007bff;
  font-weight: bold;
}

.shop-title {
  font-weight: bold;
  color: #676767;
  font-size: 17px;
  margin: 0 0 7px;
  text-transform: capitalize;
  text-align: left;
}

/* Search Box On Map Page CSS */

.search-bar .form-control {
  background-color: #ffffff;
  border-radius: 3rem;
  border: none;
  margin-bottom: 0;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 5%);
}

.search-bar .form-control:focus {
  box-shadow: none;
  outline: none;
}

/* User Address CSS */

.address-info .address-info_holder .info-list-with_icon {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 1.3rem;
  margin-bottom: 1rem;
}

.account-setting .address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.account-setting .address-info {
  display: flex;
  flex-direction: column;
}

.account-setting .address-info {
  background: #fff;
  padding: 13px 15px;
  /* border: 1.5px solid #dee2e7; */
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

.account-setting .title {
  color: #222;
  font-size: 18px;
  font-weight: 700;
}

.account-setting .settings {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0px 10px;
  font-weight: normal;
  text-transform: capitalize;
  border-radius: 3px;
  background-color: #ff6500;
  color: #fff;
}

.account-setting .settings .icon {
  margin-right: 5px;
}

.account-setting .settings:hover {
  text-decoration: none;
}

.address-info_holder .info-list-with_icon .info-icon {
  color: #ff6500;
  font-size: 20px;
  margin-right: 10px;
  margin-top: -3px;
}

.account-setting .address-info_holder .text {
  color: #444;
  font-size: 15px;
}

.account-setting .btn-outline-edit {
  color: #ff6500;
}

.account-setting .btn-outline-edit,
.account-setting .btn-outline-delete {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.03rem;
  background-color: #fff;
  padding: 3px 12px;
  border-radius: 4px;
  margin-right: 6px;
  border: 1px solid #e1e8ff;
}

.account-setting .btn-outline-delete {
  color: #ff1414;
}

.account-setting .btn-outline-delete:focus,
.account-setting .btn-outline-edit:focus,
.account-setting .btn-outline-delete:hover,
.account-setting .btn-outline-edit:hover {
  box-shadow: none;
  outline: none;
  background-color: #f7f8ff;
}

.change {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.change a,
.change .text-secondary {
  font-size: 15px;
}

/* Order List CSS */

/* Order List New */

.order-list {
  border-radius: 12px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

/* .order-list .card {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: none;
} */

.order-list .card .card-header {
  padding: 6px 10px;
  background: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #f8f8f8;
}

.order-list .card .card-body {
  padding: 12px;
}

.order-list .order-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #eee;
}

.order-list .order-item:last-child {
  margin: 0;
  border: none;
  padding: 0;
}

.order-list .badge {
  display: inline-block;
  padding: 0.5em 1.2em;
  font-size: 70%;
  font-weight: bold;
  border-radius: 2rem;
}

.order-list .order-item .order-item_img-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.order-list .order-item_image {
  width: 100px;
  height: auto;
  border-radius: 4px;
}

.order-list .order-item_name-holder {
  position: relative;
  width: 210px;
  flex: 1 1;
  flex-grow: 1;
}

.order-list .order-item_name-holder .order-item_name {
  margin-bottom: 4px;
  line-height: 1.3em;
}

.order-list .order-item_name-holder .item-price {
  font-weight: bold;
  display: block;
  font-size: 17px;
  margin-bottom: 6px;
}

.order-list .order-item .order-item_dts {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.order-list .order-item_status {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.order-list .order-item_dt {
  font-size: 12px;
  margin-top: 4px;
}

.order-item_point {
  font-weight: bold;
  font-size: 17px;
  color: #676767;
  margin: 3px 6px 0 0;
}

.order-detail .order-item_attr {
  font-size: 12px;
  color: #676767;
  margin-top: 5px;
}

/* Order Details CSS */

.order-list .order-detail .order-item_status {
  position: absolute;
  bottom: 0px;
  right: 0;
}

.order-detail_info {
  border-radius: 12px;
}

.order-detail {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 3%);
}

.order-detail .order-item_attr .sku {
  background: #f9f9f9;
  border-radius: 3px;
  padding: 3px 7px;
  font-weight: bold;
}

.order-detail .order-item {
  border-bottom: none;
}

.order-process {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-process .order-process-step {
  padding: 0;
  position: relative;
  margin-bottom: 10px;
  width: 200px;
}

.order-process .order-process-step .order-process-stepname {
  margin-top: 18px;
  color: #595959;
  font-size: 0.8rem;
  white-space: nowrap;
}

.order-process .order-process-step .order-process-dot {
  position: absolute;
  right: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin-top: -13px;
  margin-left: -15.5px;
  border-radius: 50%;
  background: #f2f2f2;
  /* Inner Dot */
}

.order-process .order-process-step .order-process-dot:after {
  content: " ";
  border-radius: 50px;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  background: #e0e0e0;
}

.order-process .order-process-step .progress {
  position: absolute;
  width: 100%;
  height: 5.56px;
  margin-top: -2.28px;
  box-shadow: none;
  border-radius: 0;
}

.order-process .order-process-step .progress .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #cae4fd;
}

.order-process .order-process-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.order-process .order-process-step:last-child > .progress {
  width: 50%;
}

.order-process .order-process-step.processing .order-process-dot,
.order-process .order-process-step.shipped .order-process-dot,
.order-process .order-process-step.delievered .order-process-dot {
  background: #cae4fd;
}

.order-process .order-process-step.processing .order-process-dot::after,
.order-process .order-process-step.shipped .order-process-dot::after,
.order-process .order-process-step.delievered .order-process-dot::after {
  background: #3780ce;
}

.order-process .order-process-step.processing .progress > .progress-bar {
  width: 100%;
}

.order-process .order-process-step.shipped .progress > .progress-bar {
  width: 100%;
}

.order-process
  .order-process-step.shipped:first-child
  > .progress
  > .progress-bar {
  width: 0%;
}

.order-process
  .order-process-step.shipped:last-child
  > .progress
  > .progress-bar {
  width: 100%;
}

.order-process .order-process-step.delievered .progress > .progress-bar {
  width: 100%;
}

.order-dt-item-price {
  font-weight: bold;
  font-size: 17px;
}

.order-detail .order-item_name {
  font-weight: normal;
  font-size: 16px;
  color: #676767;
  text-align: left;
}

.order-detail .text {
  font-size: 15px;
  color: #676767;
  margin-bottom: 4px;
}

.order-detail .address-info .text {
  font-weight: normal;
  font-size: 12px;
}

.order-detail .order-number,
.order-detail .order-date {
  font-size: 15px;
  color: #676767;
}

/* Order Confirm CSS */

.order-confirm-list {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px;
}

.order-confirm-list .order-list-img img {
  height: auto;
  width: 111px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: inline-block;
  cursor: zoom-in;
}

.ord-success-msg {
  font-size: 18px;
  font-weight: 500;
  color: #676767;
  text-align: center;
  margin-bottom: 2rem;
  padding: 0.5rem 01rem;
}

.order-confirm-list .ord-number,
.order-confirm-list .text {
  font-size: 15px;
  color: #676767;
  margin-bottom: 7px;
}

.order-confirm-list .order-item_name-holder .order-item_name {
  font-size: 15px;
  color: #676767;
  text-align: left;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin: 0 0 5px;
}

.order-confirm-list .order-item_dt {
  font-size: 12px;
  color: #676767;
  margin-bottom: 7px;
}

.order-confirm-list .sku-list {
  font-size: 12px;
  color: #676767;
  margin-bottom: 7px;
}

.order-confirm-list .sku-list span {
  background: #f9f9f9;
  border-radius: 3px;
  padding: 3px 7px;
  font-weight: bold;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .all-ctg-grid .ctg-list {
    flex: 0 1 33.333%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .all-ctg-grid .ctg-list {
    flex: 0 1 33.333%;
  }
}

/* Media Queries */

@media only screen and (max-width: 767.98px) {
  .mobile-product-grid {
    display: flex;
    flex: 0 1 50%;
  }
  .all-ctg-grid .ctg-list {
    flex: 0 1 50%;
  }
  .all-ctg-grid .ctg-item-img {
    width: 75px;
    height: 75px;
    border-radius: 100%;
  }
  .order-list .order-item_status {
    position: relative;
    bottom: 0;
    right: 0;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .mobile-product-grid {
    display: flex;
    flex: 0 1 33.333%;
  }
}

.reg-link {
  padding: 9px 15px;
  display: block;
  background-color: #f9f9f9;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 15px;
  border-radius: 6px;
  text-align: center;
}

.reg-link:focus,
.reg-link:hover {
  text-decoration: none;
}

.divide-word {
  display: block;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  margin: 15px 0;
}

.small-label {
  font-size: 0.8rem;
  color: #666;
  line-height: normal;
  text-align: center;
}

.filter-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-holder .icon {
  font-size: 1.2rem;
  color: #555;
}

.filter-holder .label {
  color: #555;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 5px;
}

/* Loading Spinner CSS */

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

.loading .letter-holder {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loading .letter {
  float: left;
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: sans-serif;
}

/* Transparent Overlay */

.loading .letter {
  -webkit-animation-name: loadingF;
          animation-name: loadingF;
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
          animation-direction: linear;
}

.loading .l-1 {
  -webkit-animation-delay: 0.48s;
          animation-delay: 0.48s;
}

.loading .l-2 {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.loading .l-3 {
  -webkit-animation-delay: 0.72s;
          animation-delay: 0.72s;
}

.loading .l-4 {
  -webkit-animation-delay: 0.84s;
          animation-delay: 0.84s;
}

.loading .l-5 {
  -webkit-animation-delay: 0.96s;
          animation-delay: 0.96s;
}

.loading .l-6 {
  -webkit-animation-delay: 1.08s;
          animation-delay: 1.08s;
}

.loading .l-7 {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.loading .l-8 {
  -webkit-animation-delay: 1.32s;
          animation-delay: 1.32s;
}

.loading .l-9 {
  -webkit-animation-delay: 1.44s;
          animation-delay: 1.44s;
}

.loading .l-10 {
  -webkit-animation-delay: 1.56s;
          animation-delay: 1.56s;
}

/* Animation */

@-webkit-keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn-info-custom {
  display: block;
  width: 100%;
  background-color: #dce6ff;
  color: #007bff;
  font-weight: bold;
  padding: 10px 12px;
  font-size: 12px;
  line-height: normal;
}

.btn-info-custom:focus,
.btn-info-custom:hover {
  color: #005ec2;
}

.btn-info-custom-icon {
  font-size: 15px;
  margin-right: 10px;
  margin-top: -2px;
}

/* Bootstrap Overwrites */

.form-control,
.custom-select {
  border: 1px solid #dee2e7;
  border-radius: 5px;
  height: 45px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f;
  opacity: 1;
  /* Firefox */
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8f8f;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f8f8f;
}

.btn {
  text-transform: capitalize;
  /* border: none; */
  font-size: 0.9rem;
  border-radius: 5px;
  /* letter-spacing: 0.02rem; */
}

.btn-sm {
  font-size: 11px;
}
.btn-link {
  font-weight: 400;
  color: #ff6500;
  text-decoration: none;
}
.btn-link:hover {
  font-weight: 400;
  color: #ffb300;
  text-decoration: none;
}
.btn-primary {
  color: #fff;
  background-color: #ff6500;
  border-color: #ff6500;
}

.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff8c00;
  border-color: transparent;
}

.btn-outline-primary {
  color: #ff6500;
  border-color: #ff6500;
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.btn-order-confirm {
  font-size: 12px;
  height: 45px;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.card {
  border: none;
  border-radius: 10px;
}

.line-h-normal {
  line-height: normal;
}

.alert-icon {
  color: #676767;
  background: #d0d0d0;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 1rem;  */
}

.alert-icon.success {
  color: #0c940c;
  background: #b6ffb6;
}

.alert-icon.warning {
  color: #f79605;
  background: #f7dfa9;
}

.alert-icon.danger {
  color: #f70525;
  background: #ffb8c1;
}

.alert-icon .icon {
  font-size: 1.5rem;
}

.choose-shipping-address .shipping-info {
  box-shadow: none;
  border: 1px solid #dedede;
  cursor: pointer;
}

.choose-shipping-address .shipping-info.selected {
  border: 2px solid #007bff;
}

/* Modal CSS */

.modal-dialog {
  position: relative;
  width: 300px;
  margin: auto;
  pointer-events: none;
}

.modal-header {
  padding: 5px 20px;
  border-bottom: none;
}

.modal-icon {
  width: 35px;
  height: 35px;
  margin-bottom: 0.8rem;
}

.modal-text {
  color: #676767;
  font-weight: normal;
  font-size: 14px;
}

.modal-content {
  border-radius: 10px;
}

.modal-message {
  color: #676767;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.close {
  position: absolute;
  right: 6px;
  top: 5px;
  width: 18px;
  height: 18px;
  opacity: 0.3;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

.shop-info-header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 3%);
}

.shop-info-header img {
  width: 45px;
  height: auto;
  padding: 3px;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-right: 1rem;
}

.shop-info-header .name {
  color: #777777;
  font-size: 16px;
  margin: 0;
}

#loading {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
}

#loading-image {
  position: absolute;
  top: 40%;
  left: 44%;
  z-index: 100;
}

/* New CSS */
/* Header CSS */
.toggle-icon {
  width: 25px;
  height: auto;
}
.home__content-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* margin-bottom: 2rem; */
}
.rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rating .icon {
  color: #676767;
  font-size: 22px;
}
.rating span {
  color: #676767;
  font-size: 22px;
  font-weight: bold;
}
.btn-pickup,
.btn-dinein {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

/* Pick Up CSS */
.pick-up__info,
.dine-in__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block-sub__label {
  color: #676767;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.store-icon {
  font-size: 30px;
  color: #ff6500;
  margin-top: -5px;
  margin-right: 10px;
}

/* Offcanvas Menu Css */
.offcanvas-title {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 15px 20px;
  background: #581c8c;
  color: #fff;
  font-size: 20px;
}
.offcanvas-menu_link {
  display: flex;
  padding: 15px 20px;
  color: #565353;
  font-size: 16px;
}
.offcanvas-menu_link:focus,
.offcanvas-menu_link:hover {
  text-decoration: none;
  color: #fff;
}
.offcanvas-menu_icon {
  margin-right: 5px;
}
.offcanvas-menu_icon .icon {
  font-size: 22px;
  color: #581c8c;
}

.my-card {
  background: url(/static/media/card.93a3b6ea.png) no-repeat;
  background-size: contain;
  background-size: 100% 100%;
  width: 100% !important;
  height: 200px;
}


.luckydraw_b {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background: url(/static/media/luckydraw_b.e676cf91.png) center/cover no-repeat;
}

.luckydraw-text-container {
  position: relative;
  z-index: 1;
  top: 10%;
  background: rgba(255, 255, 255, 0.8); /* Adjust the last value (alpha) for transparency */
  padding: 20px;
  border-radius: 10px; /* Optional: add rounded corners */
  font-size: 10px;
  font-weight: bold;
}

/* Media Query for smaller screens */
@media screen and (max-width: 768px) {
  .luckydraw_b {
    height: auto; /* Adjust the height as needed */
  }

  .luckydraw-text-container {
    max-width: 100%; /* Adjust the maximum width of the text container */
    margin: 0 auto; /* Center the text container horizontally */
  }
}

.push-right {
  float: right;
}

.text-purple {
  color: #581c8c;
}

/* @media (max-width: 576px) {
  .swiper-wrapper img{
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .swiper-wrapper img{
    width: 700px;
    height: auto;
  }
}

@media (max-width: 1200px) {
  .swiper-wrapper img{
    width: 1000px;
    height: auto;
  }
} */

.swiper-wrapper img{
  width: 100%;
  height: auto;
  object-fit: cover;
}

tr.no-border td {
  border-top: none;
  border-bottom: none;
}


