.modal {
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear; 
  }
  
  .modal.show {
    opacity: 1;
    transform: scale(1);
  }
.modal-icon {
    width: 35px;
    height: 35px;
    margin-bottom: 0.8rem;
  }
  .modal-dialog {
    position: relative;
    width: 330px;
    margin: auto;
    pointer-events: none;
  }
  /* @media (min-width: 576px) {
    .modal-dialog-box {
        max-width: 300px;
        margin: 1.75rem auto;
    }
    } */