#switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 24px;
}
  
#switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a5a5a5;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: rgb(255, 255, 255);
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #00d47a;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #00d47a;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(30px);
}
  
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}